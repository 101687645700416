import {Box} from "@mui/material";
import isEqual from "lodash.isequal";
import React, {memo, useEffect, useState} from 'react';

import {MapContainer, Circle, TileLayer, Rectangle} from 'react-leaflet'
import {LatLng, LatLngBounds, LeafletMouseEvent, Map as LeafletMap} from "leaflet";
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from "recoil";
import {flyToLocation, mapBounds, mapCenter, searchBounds, searchRadius, showTraffic, showSatellite} from "../../hooks/shared-state";
import {categoryState} from "../../hooks/category/category-state";
import {useLayerCollections} from "../../hooks/layers/layer-state";
import {MeasureLines, useMeasurementTool } from './measurement';
import VectorTileLayer from "./vector-tile-map";
import Overlay from "./overlay";
import Markers from "./markers";
import Layers from "./features";
import SearchArea from "./search-area";
import MapControls from "./map-controls";

function useMapLocations(map: LeafletMap | undefined, onLoad: (map: LeafletMap) => void) {
  const setCenter = useSetRecoilState(mapCenter);
  const setBounds = useSetRecoilState(mapBounds);
  function setMapState() {
    const center = map!.getCenter();
    const bounds = map!.getBounds();
    const southWest = bounds.getSouthWest();
    const northEast = bounds.getNorthEast();
    setCenter([center.lat, center.lng]);
    setBounds({min: [southWest.lat, southWest.lng], max: [northEast.lat, northEast.lng]})
  }
  useEffect(() => {
    if(map) { setMapState(); onLoad(map); }
    map?.on('moveend', () => setMapState());
  }, [map]);
}

function useFlyTo(map: LeafletMap | undefined) {
  const [flyTo, setFlyTo] = useRecoilState(flyToLocation);
  useEffect(() => {
    if(flyTo) {
      if(flyTo.length === 1) {
        map?.flyToBounds(new LatLngBounds([flyTo[0].latitude, flyTo[0].longitude], [flyTo[0].latitude, flyTo[0].longitude]))
      }
      else if(flyTo.length === 2) {
        map?.flyToBounds(new LatLngBounds([flyTo[0].latitude, flyTo[0].longitude], [flyTo[1].latitude, flyTo[1].longitude]))
      } else if(flyTo.length === 3) {
        map?.flyTo(new LatLng(flyTo[0], flyTo[1]), flyTo[2]);
      }
      setFlyTo(undefined);
    }
  }, [flyTo]);
}

function useInitialLayers(category: string) {
  const categoryRoot = useRecoilValue(categoryState(category));
  const layerService = useLayerCollections(category);
  useEffect(() => {
    categoryRoot?.initialLayerIds?.map(layer => layerService.updateCollection(category, layer, true));
  }, [categoryRoot])
}

function useSelection(map: LeafletMap | undefined) {
  const [selected, setSelected] = useState(false);
  const [box, setBox] = useRecoilState(searchBounds);
  const [phase, setPhase] = useState<number>(0);
  useEffect(() => {
    map?.removeEventListener('click');
    map?.removeEventListener('mousemove');
    if(selected) {
      map?.on('click', (e: LeafletMouseEvent) => {
          if(phase === 1 || phase === 3) {
            setBox({start: [e.latlng.lat, e.latlng.lng], end: [e.latlng.lat, e.latlng.lng], min: [e.latlng.lat, e.latlng.lng], max: [e.latlng.lat, e.latlng.lng]});
            setPhase(2);
          } else if(phase === 2) {
            setPhase(3);
          }
      });
      map?.on('mousemove', (e: LeafletMouseEvent) => {
        if(phase === 2 && box) {
          setBox(b => {
            if(b) {
              const startLat = Math.min(b!.start[0], e.latlng.lat);
              const startLong = Math.min(b!.start[1], e.latlng.lng);
              const endLat = Math.max(b!.start[0], e.latlng.lat);
              const endLong = Math.max(b!.start[1], e.latlng.lng);
              return {
                start: b!.start,
                end: [e.latlng.lat, e.latlng.lng],
                min: [startLat, startLong],
                max: [endLat, endLong]
              };
            }
            return b;
          });
        }
      });
    }
  }, [map, selected, phase, box]);
  return {
    selected,
    box,
    onClick: () => {
      setBox(undefined);
      if(!selected) {
        setPhase(1);
      }
      setSelected(s => !s)
    }
  }
}

function Map({category, onLoad, centre, zoom, isStatic}: {isStatic: boolean; category: string; centre: [number, number]; zoom: number; onLoad: (map: LeafletMap) => void}): JSX.Element {
  const [map, setMap] = useState<LeafletMap | undefined>(undefined);
  const searchCircleRadius = useRecoilValue(searchRadius);
  const shouldShowTraffic = useRecoilValue(showTraffic);
  const shouldShowSatellite = useRecoilValue(showSatellite);
  const categoryInfo = useRecoilValue(categoryState(category));
  const {selected, box, onClick: selectionControlClicked} = useSelection(map);
  const {selected: mSelected, onClick: measurementToolClicked, box: drawing, lines} = useMeasurementTool(map);
  useMapLocations(map, onLoad);
  useFlyTo(map);
  useInitialLayers(category);
  useEffect(() => {
    if(categoryInfo) map?.flyTo(new LatLng(categoryInfo!.centre.latitude, categoryInfo.centre.longitude), categoryInfo!.zoom)
  }, [category]);
  useEffect(() => {
    if(selected) {
      map?.dragging.disable();
    } else {
      map?.dragging.enable();
    }
  }, [selected])
  return (
      <Box sx={{height: '100%', flex: 1, cursor: selected ? 'default' : 'grab', '& .map-cursor-control': { cursor: `${mSelected ? 'default': 'grab'} !important` }}}>
        <MapContainer whenCreated={setMap} style={{height: '100%', cursor: 'unset'}} className="map-cursor-control" center={centre} zoom={zoom} minZoom={7} maxZoom={20} scrollWheelZoom={!isStatic} zoomControl={false}>
          <VectorTileLayer attribution={!isStatic ? `Contains OS data &copy; Crown copyright and database rights ${new Date().toISOString().substring(0,4)}` : undefined} styleUrl="https://raw.githubusercontent.com/OrdnanceSurvey/OS-Vector-Tile-API-Stylesheets/master/OS_VTS_3857_Light.json"/>
          <TileLayer
            url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
            maxZoom= {20}
            minZoom={7}
            subdomains={['mt1','mt2','mt3']}
            opacity={shouldShowSatellite ? 1 : 0}
          />
          <TileLayer
            url="https://api.tomtom.com/traffic/map/4/tile/flow/relative0/{z}/{x}/{y}.png?key=rpmySwpBWF7Cn1eRglBuFxztGKJuGTbf"
            opacity={shouldShowTraffic ? 1 : 0}
          />
          { searchCircleRadius && !box && <Circle opacity={0.2} radius={searchCircleRadius} center={map?.getCenter() ?? centre} /> }
          <Overlay category={category}/>
          <Markers category={category}/>
          <Layers category={category}/>
          {selected && box && <Rectangle bounds={new LatLngBounds(new LatLng(box.min[0], box.min[1]), new LatLng(box.max[0], box.max[1]))} fillColor="#444444" stroke={false}/>}
          {!isStatic && <MapControls position="topright" selection={{selected, onClick: selectionControlClicked}} measure={{selected: mSelected, onClick: measurementToolClicked}} /> }
          {!isStatic && mSelected && <MeasureLines drawing={drawing} lines={lines} />}
        </MapContainer>
        {!isStatic && <SearchArea category={category}/> }
      </Box>
  );
}

export default memo(Map, isEqual);
